import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from "../components/Layout/Layout";
import Hero from '../components/Hero/Hero';
import Seo from "../components/Seo/Seo";
import { BlockA, BlockB, BlockQuote, BlockF, BlockVR, BlockVideo } from '../components/BlockModule/BlockModule';
import { setCurrentPage } from "../actions";

// markup
const IndexPage = ({data}) => {
  const dispatch = useDispatch();
  const navTriggerRef = useRef();
  const [ Trigger, setTrigger] = useState();

  const document = data.allPrismicIndex.edges[0].node.data;
  const blockAData = document.body[0].primary;
  const blockQuoteData = document.body[1].primary;
  const blockBData = document.body[2].primary;
  const blockFData = document.body[3];
  //const blockVRData = document.body[4] || null;
  const blockVideoData = document.body[4] || null;

  console.log(blockVideoData.primary);

  useEffect(() => {
    dispatch(setCurrentPage('/'));
    setTrigger(navTriggerRef.current);

  },[]);

  return (
    <Layout trigger={Trigger} defaultColor="white" omitLogo footer>
      <Seo title="Landing"/>
      <Hero 
        ref={navTriggerRef} 
        desktop={{src: document.hero_image[0].image.url, placeholder: document.hero_image[0].image.url+'&w=100&blur=10&q=50'}} 
        mobile={{src: document.hero_image[1].image.url, placeholder: document.hero_image[1].image.url+'&w=100&blur=10&q=50'}} logo
      />
      <BlockA image={{
        src: blockAData.image.url, 
        placeholder: blockAData.image.url+'&w=100&blur=10&q=50', 
        width: blockAData.image.dimensions.width, 
        height: blockAData.image.dimensions.height
      }} title={blockAData.title} html={blockAData.copy.html} animated={blockAData.animated}/>
    <BlockQuote medium animated>{ blockQuoteData.quote }</BlockQuote>
    <BlockB image={{src: blockBData.image.url, placeholder: blockBData.image.url+'&w=100&blur=10&q=50', width: blockBData.image.dimensions.width, height: blockBData.image.dimensions.height}} animated/>
    <BlockF title={blockFData.primary.title} data={[
    {
      callToAction: {label:blockFData.items[0].call_to_action_label, href:blockFData.items[0].call_to_action_link},
      image: {src: blockFData.items[0].image.url, placeholder: blockFData.items[0].image.url+'&w=100&blur=10&q=50', alt: blockFData.items[0].image.alt, width:blockFData.items[0].image.dimensions.width, height:blockFData.items[0].image.dimensions.height},
      title: blockFData.items[0].title,
      html: blockFData.items[0].copy.html,
    }
  ]} animated/>
    { /** blockVRData ? <BlockVR 
      copy={blockVRData.primary.text}
      src={blockVRData.primary['the_viewer_link'].url}
animated /> : null  */}
  { blockVideoData ? <BlockVideo
      copy={blockVideoData.primary.text}
      src={blockVideoData.primary['video'].url}
      animated /> : null }
    
    </Layout>
  )
}

export const query = graphql`
query IndexQuery {
  allPrismicIndex {
    edges {
      node {
        data {
          hero_image {
            breakpoint
            image {
              url
              dimensions {
                height
                width
              }
            }
          }
          body {
            ... on PrismicIndexBodyBlockA {
              id
              primary {
                animated
                title
                margin
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                  alt
                }
                copy {
                  html
                }
              }
            }
            ... on PrismicIndexBodyBlockQuote {
              id
              primary {
                quote: copy
              }
            }
            ... on PrismicIndexBodyBlockB {
              id
              primary {
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                  alt
                }
              }
            }
            ... on PrismicIndexBodyBlockF {
              id
              primary {
                title
              }
              items {
                call_to_action_label
                call_to_action_link
                title
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                  alt
                }
                copy {
                  html
                }
              }
            }
            ... on PrismicIndexBodyBlockVr {
              id
              primary {
                text: copy
                the_viewer_link {
                  url
                }
              }
            }
            ... on PrismicIndexBodyBlockVideo {
              id
              primary {
                text: copy
                video {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default IndexPage
